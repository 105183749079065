@import "./tailwind.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eceff1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.align-col-center {
  display: flex;
  justify-content: center;
}
.itemlist {
  list-style: none;
}

.itemlist > li {
  padding: 5px 0;
  font-weight: 300;
}
.page-header {
  font-weight: 300;
  font-size: 2rem;
}

.page-subheader {
  font-weight: 300;
  font-size: 1.1rem;
}
.space-between-elements {
  display: flex;
  justify-content: space-between;
}
.ml-3 {
  margin-left: 10px;
  margin-top: 10px;
}
@media (min-width: 320px) {
  .page-header {
    font-weight: 300;
    font-size: 1.4rem;
  }

  .page-subheader {
    font-weight: 300;
    font-size: 0.9rem;
  }
}
@media (min-width: 480px) {
  .page-header {
    font-weight: 300;
    font-size: 1.5rem;
  }

  .page-subheader {
    font-weight: 300;
    font-size: 1rem;
  }
}
@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .page-header {
    font-weight: 300;
    font-size: 1.8rem;
  }
  .padding-for-bigger-screens {
    padding: 0 2rem;
  }

  .page-subheader {
    font-weight: 300;
    font-size: 1rem;
  }
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
  .page-header {
    font-weight: 300;
    font-size: 1.9rem;
  }

  .page-subheader {
    font-weight: 300;
    font-size: 1.1rem;
  }
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .page-header {
    font-weight: 300;
    font-size: 1.9rem;
  }

  .page-subheader {
    font-weight: 300;
    font-size: 1.1rem;
  }
}

.floating-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.floating-button {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  font-weight: 700;
  background-color: #ff9900;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  position: absolute; /* Add this line */
  bottom: 0; /* Add this line */
  right: 0; /* Add this line */
}
.s-content {
  font-weight: 500 !important;
}

.text-black {
  color: black;
  font-weight: 600;
  font-size: 0.9rem;
}

.tooltip-inner {
  background-color: #0d6efd; /* Change to your desired background color */
  color: white; /* Change text color for better contrast */
}

/* Additional styles to enhance the appearance of the tooltip */
.tooltip {
  max-width: px; /* Adjust the maximum width as needed */
  text-align: center; /* Center the tooltip content */
}

@media (max-width: 768px) {
  .floating-button-container {
    bottom: 10px;
    right: 10px;
  }
  .floating-button {
    width: 60px;
    height: 60px;
    font-weight: 700;
    font-size: 0.8rem;
  }
}
